import { firestore } from "@/firebase";
import { Group } from "@/models/group";

const getEmailGroup =
  (orgId: string, emailDomain: string) => async (): Promise<Group> => {
    if (orgId) {
      console.log("should get email group", orgId, emailDomain);
      if (!orgId || !emailDomain) return null;
      const groupDoc = await firestore
        .collection("orgs")
        .doc(orgId)
        .collection("groups")
        .where("emailDomain", "==", emailDomain)
        .get();

      if (groupDoc.size) return groupDoc.docs[0].data() as Group;
      else return null;
    } else return null;
  };

export default getEmailGroup;
