import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
};
const GoogleIcon: React.FC<Props> = (props) => {
  return (
    <span style={props.style}>
      <svg
        width={String(props.size)}
        height={String(props.size)}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.7441 10.5519H24.7372V10.5H13.4872V15.5H20.5516C19.5209 18.4106 16.7516 20.5 13.4872 20.5C9.34531 20.5 5.98718 17.1419 5.98718 13C5.98718 8.85813 9.34531 5.5 13.4872 5.5C15.3991 5.5 17.1384 6.22125 18.4628 7.39937L21.9984 3.86375C19.7659 1.78312 16.7797 0.5 13.4872 0.5C6.58406 0.5 0.987183 6.09687 0.987183 13C0.987183 19.9031 6.58406 25.5 13.4872 25.5C20.3903 25.5 25.9872 19.9031 25.9872 13C25.9872 12.1619 25.9009 11.3438 25.7441 10.5519Z"
          fill="#FFC107"
        />
        <path
          d="M2.42844 7.18187L6.53531 10.1938C7.64656 7.4425 10.3378 5.5 13.4872 5.5C15.3991 5.5 17.1384 6.22125 18.4628 7.39937L21.9984 3.86375C19.7659 1.78312 16.7797 0.5 13.4872 0.5C8.68594 0.5 4.52219 3.21062 2.42844 7.18187Z"
          fill="#FF3D00"
        />
        <path
          d="M13.4872 25.5C16.7159 25.5 19.6497 24.2644 21.8678 22.255L17.9991 18.9813C16.7019 19.9677 15.1169 20.5013 13.4872 20.5C10.2359 20.5 7.47532 18.4269 6.43532 15.5338L2.35907 18.6744C4.42782 22.7225 8.62907 25.5 13.4872 25.5Z"
          fill="#4CAF50"
        />
        <path
          d="M25.7441 10.5519H24.7372V10.5H13.4872V15.5H20.5516C20.0586 16.8853 19.1705 18.0957 17.9972 18.9819L17.9991 18.9806L21.8678 22.2544C21.5941 22.5031 25.9872 19.25 25.9872 13C25.9872 12.1619 25.9009 11.3438 25.7441 10.5519Z"
          fill="#1976D2"
        />
      </svg>
    </span>
  );
};
GoogleIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default GoogleIcon;
