import * as firebaseClient from "@/firebase";
import { Org } from "@/models/org";
import { Program, Step } from "@/models/program";
import { User } from "@/models/user";
import * as Sentry from "@sentry/nextjs";

const updateUser = async (data: {
  userId: string;
  userUpdate: Partial<User>;
}): Promise<void> => {
  try {
    const { userId, userUpdate } = data;
    console.log(userId, userUpdate);
    const userDoc = await firebaseClient.firestore
      .collection("users")
      .doc(userId)
      .get();
    if (userDoc.exists) {
      await userDoc.ref.update(userUpdate);
    } else throw new Error("Could not retrieve user");
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

export default updateUser;
