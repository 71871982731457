import Button from "@/components/universal/Button/Button";
import styled from "styled-components";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootState } from "@/redux/index";
import { authSelectors, GoogleAuth, LogOut } from "@/redux/auth";
import { colors } from "@/lib/styles";
import Title from "../universal/Text/Title";
import GoogleIcon from "../icons/GoogleIcon";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import useOwner from "@/hooks/useOwner";
import * as EmailValidator from "email-validator";
import Logo from "../brand/Logo";
import Loading from "../universal/Loading";
import { useMutation } from "react-query";
import { Formik } from "formik";
import LockIcon from "../icons/LockIcon";
import api from "@/db/utils/api";
import { auth, firestore } from "@/firebase";
import getPrimaryWorkspace from "@/db/user/getPrimaryWorkspace";
import TextButton from "../universal/Button/TextButton";
import { Org } from "@/models/org";
import Image from "next/image";
import toast from "react-hot-toast";
import { ownerColor, ownerDomain } from "@/lib/owner";
import Link from "next/link";
import { ownerPath } from "@/lib/path";
import updateUser from "@/db/user/updateUser";
import firebase from "firebase";
import getEmailGroup from "@/db/group/getEmailGroup";
import Legal from "@/components/Legal";
import { Group } from "@/models/group";
import * as Sentry from "@sentry/nextjs";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  flex-wrap: wrap;
  background-color: ${colors.nearWhite};
`;

const Card = styled.div`
  width: 400px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  padding: 20px 30px;
  border-radius: 10px;
  border: 1px solid ${colors.lightGray};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
`;

const Or = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 15px 0 5px;
`;

const OrBar = styled.div`
  height: 1px;
  background-color: ${colors.lightGray};
  width: 100%;
  position: absolute;
  bottom: 50%;
  z-index: 1;
`;

const OrText = styled.div`
  color: ${colors.gray};
  font-size: 16px;
  padding: 0 20px;
  background-color: ${colors.white};
  z-index: 2;
`;

const InputWrapper = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  position: relative;
`;

const StyledInput = styled.input<{ error?: boolean }>`
  width: 100%;
  border-radius: 10px;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.nearBlack};
  outline: none;
  padding: 17px 20px 17px 60px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid ${(props) => (props.error ? colors.red : colors.lightGray)};
  background-color: ${colors.white};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &::placeholder {
    color: ${colors.nearBlack};
  }
`;

const CodeLabel = styled.p`
  color: ${colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  margin: 5px 0 5px;
`;

const CodeEmail = styled.p`
  color: ${colors.nearBlack};
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin: 5px 0 20px;
`;

const OrgHeader = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const OrgImage = styled.div`
  margin-right: 10px;
  height: 40px;
  width: 40px;
  position: relative;
`;

const OrgTitle = styled.h1`
  color: ${colors.nearBlack};
  font-size: 28px;
  font-weight: 600;
  margin: 0;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
`;

const Help = styled.div`
  color: ${colors.gray};
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Error = styled.p`
  font-size: 12px;
  color: ${colors.red};
  font-weight: 400;
  width: 100%;
  text-align: left;
`;

type Props = {
  title?: string;
  next?: string;
  org?: Org;
} & ConnectedProps<typeof connector>;

const LogIn: React.FC<Props> = (props) => {
  const { title, next, org } = props;
  const router = useRouter();
  const owner = useOwner();
  const [email, setEmail] = useState("");
  const n = next || (router.query.next as string) || null;

  // login with google and progress to appropriate screen
  const googleLogin = useCallback(async () => {
    await props.GoogleAuth(async (workspace, userId, userEmail) => {
      const explicitGroupId = router.query.group as string;
      if (owner) {
        let group: Group;
        if (explicitGroupId) {
          // get group
          const groupDoc = await firestore
            .collection("orgs")
            .doc(owner.id)
            .collection("groups")
            .doc(explicitGroupId)
            .get();
          group = groupDoc.data() as Group;
        } else {
          console.log(userEmail);
          const emailGroup = await getEmailGroup(
            owner.id,
            userEmail?.split("@")?.[1]
          )();
          if (emailGroup) group = emailGroup;
        }

        if (group) {
          if (group.parent) {
            await updateUser({
              userId,
              userUpdate: {
                memberships: firebase.firestore.FieldValue.arrayUnion(
                  `${owner.id}/${group.parent}`
                ) as any,
              },
            });
          }
          await updateUser({
            userId,
            userUpdate: {
              memberships: firebase.firestore.FieldValue.arrayUnion(
                group.parent
                  ? `${owner.id}/${group.parent}/${group.id}`
                  : `${owner.id}/${group.id}`
              ) as any,
            },
          });
        }
      }
      if (owner) {
        router.push(n ? n : ownerPath(owner, ""));
      } else {
        router.push(
          n || workspace || owner ? ownerPath(owner, "access") : "/access"
        );
      }
    }, n);
  }, [n, owner]);

  const startEmailAuth = useMutation(
    async (email: string) => {
      const { data } = await api().post("/auth/code", {
        email,
        org: org
          ? {
              title: org.title,
              logo: org.image,
              url: ownerDomain(org),
              color: org.color,
              slug: org.slug,
            }
          : undefined,
      });
      const authKey = data.key;
      setEmail(email);
      return authKey;
    },
    {
      onError: (error: any) => {
        Sentry.captureException(error);
        toast.custom("Could not authenticate email - try refreshing the page");
      },
    }
  );

  const confirmEmailAuth = useMutation(
    async (code: string) => {
      const { data } = await api().post("/auth/token", {
        key: startEmailAuth.data,
        code,
      });
      const uid = data.uid as string;
      const token = data.token as string;
      await auth.signInWithCustomToken(token);
      const workspace = await getPrimaryWorkspace({ uid });
      const explicitGroup = router.query.group as string;
      if (owner) {
        const emailGroup = await getEmailGroup(
          owner.id,
          email?.split("@")?.[1]
        )();
        const group = explicitGroup || emailGroup;
        if (group)
          await updateUser({
            userId: uid,
            userUpdate: {
              memberships: firebase.firestore.FieldValue.arrayUnion(
                `${owner.id}/${group}`
              ) as any,
            },
          });
      }
      if (owner) {
        router.push(n ? n : ownerPath(owner, ""));
      } else {
        router.push(
          n || workspace || owner ? ownerPath(owner, "access") : "/access"
        );
      }
    },
    {
      onError: (error: any) => {
        Sentry.captureException(error);
        toast.custom("Could not confirm email - try refreshing the page");
      },
    }
  );

  return (
    <>
      <Wrapper>
        {org ? (
          <Link passHref href={ownerPath(org, "")}>
            <OrgHeader>
              {org.image && (
                <OrgImage>
                  <Image src={org.image} layout="fill" objectFit={"contain"} />
                </OrgImage>
              )}
              <OrgTitle>{org.title}</OrgTitle>
            </OrgHeader>
          </Link>
        ) : (
          <Logo size={28} color={colors.nearBlack} />
        )}
        <Card>
          <Title style={{ marginBottom: 20, fontSize: 28, marginTop: 10 }}>
            {title || "Log in to your account"}
          </Title>

          {!startEmailAuth.isSuccess && (
            <Formik
              initialValues={{ email: "" }}
              enableReinitialize
              onSubmit={(values) => startEmailAuth.mutate(values.email)}
              validate={({ email }) => {
                if (!EmailValidator.validate(email))
                  return { email: "Invalid email" };
              }}
            >
              {({
                handleChange,
                dirty,
                handleBlur,
                handleSubmit,
                values,
                isValid,
                errors,
                submitCount,
              }) => (
                <>
                  {/* <InputWrapper onSubmit={handleSubmit}>
                    <EmailIcon
                      size={21}
                      color={
                        !!(dirty && submitCount > 0 && errors?.email)
                          ? colors.red
                          : colors.nearBlack
                      }
                      style={{
                        position: "absolute",
                        left: 20,
                        transform: "translateY(2px)",
                      }}
                    />
                    <StyledInput
                      value={values.email}
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      type={"email"}
                      data-cy={`start-input`}
                      placeholder="Email"
                      error={!!(dirty && submitCount > 0 && errors?.email)}
                      autoFocus
                    />
                  </InputWrapper>
                  {dirty && submitCount > 0 && errors?.email && (
                    <Error>{errors.email}</Error>
                  )} */}
                  {dirty ? (
                    <>
                      <Button
                        submit
                        disabled={startEmailAuth.isLoading}
                        backgroundColor={ownerColor(org)}
                        color={colors.white}
                        style={{
                          paddingTop: 20,
                          paddingBottom: 20,
                          marginTop: 20,
                          marginBottom: 10,
                        }}
                        onClick={() => handleSubmit()}
                      >
                        {startEmailAuth.isLoading ? (
                          <Loading size={21} light />
                        ) : (
                          "Continue"
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      {/* <Or>
                        <OrBar />
                        <OrText>OR</OrText>
                      </Or> */}
                      <Button
                        backgroundColor={colors.nearWhite}
                        hoverBackgroundColor={colors.nearWhite}
                        color={colors.nearBlack}
                        hoverColor={colors.nearBlack}
                        style={{
                          fontSize: 16,
                          marginBottom: 10,
                          paddingTop: 15,
                          paddingBottom: 15,
                        }}
                        onClick={googleLogin}
                      >
                        <GoogleIcon size={21} style={{ marginRight: 20 }} /> Log
                        in with Google
                      </Button>
                    </>
                  )}
                </>
              )}
            </Formik>
          )}
          {startEmailAuth.isSuccess && (
            <Formik
              initialValues={{ code: "" }}
              enableReinitialize
              onSubmit={(values) => confirmEmailAuth.mutate(values.code)}
              validate={({ code }) => {
                if (code.length !== 6 || !/^-?\d+$/.test(code))
                  return { code: "Code must be 6 numbers" };
              }}
            >
              {({
                handleChange,
                dirty,
                handleBlur,
                handleSubmit,
                submitCount,
                errors,
                values,
                isValid,
              }) => (
                <>
                  <CodeLabel>Enter the 6-digit code we just sent to</CodeLabel>
                  <CodeEmail>{email}</CodeEmail>
                  <InputWrapper onSubmit={handleSubmit}>
                    <LockIcon
                      size={21}
                      color={
                        !!(dirty && submitCount > 0 && errors?.code)
                          ? colors.red
                          : colors.nearBlack
                      }
                      style={{
                        position: "absolute",
                        left: 20,
                        transform: "translateY(2px)",
                      }}
                    />
                    <StyledInput
                      value={values.code}
                      onChange={handleChange("code")}
                      onBlur={handleBlur("code")}
                      type={"text"}
                      data-cy={`start-code`}
                      placeholder="Code"
                      error={!!(dirty && submitCount > 0 && errors?.code)}
                      autoFocus
                    />
                  </InputWrapper>
                  {dirty && submitCount > 0 && errors?.code && (
                    <Error>{errors.code}</Error>
                  )}
                  <Button
                    submit
                    disabled={confirmEmailAuth.isLoading}
                    backgroundColor={
                      isValid && dirty ? ownerColor(org) : colors.nearWhite
                    }
                    color={isValid && dirty ? colors.white : colors.nearBlack}
                    style={{
                      paddingTop: 20,
                      paddingBottom: 20,
                      marginTop: 20,
                      marginBottom: 10,
                    }}
                    onClick={() => handleSubmit()}
                  >
                    {confirmEmailAuth.isLoading ? (
                      <Loading size={21} light />
                    ) : (
                      "Continue"
                    )}
                  </Button>
                  <TextButton
                    onClick={() => {
                      startEmailAuth.reset();
                      setEmail("");
                    }}
                    color={colors.darkGray}
                    hoverColor={colors.nearBlack}
                    style={{ marginTop: 10 }}
                  >
                    Use a different email
                  </TextButton>
                </>
              )}
            </Formik>
          )}
        </Card>
        <Help>
          If you need help,
          <TextButton
            href={`mailto:team@steppable.com?subject=Help%20logging%20into%20${
              org ? encodeURIComponent(org.title) : "Steppable"
            }`}
            target={"_blank"}
            color={colors.darkGray}
            hoverColor={colors.nearBlack}
            style={{ paddingLeft: 5, paddingRight: 5 }}
          >
            contact support
          </TextButton>
        </Help>
        <Footer>
          {/* {org && (
            <Logo
              target="_blank"
              size={21}
              color={colors.gray}
              hoverColor={colors.nearBlack}
            />
          )} */}
          {owner && <Legal />}
        </Footer>
      </Wrapper>
    </>
  );
};

const mapState = (state: RootState) => ({
  loggedIn: authSelectors.loggedIn(state),
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      GoogleAuth,
      LogOut,
    },
    dispatch
  );
const connector = connect(mapState, mapDispatch);

export default connector(LogIn);
